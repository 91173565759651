/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import loadable from '@loadable/component';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useHistory } from 'react-router-dom';
import parse from 'html-react-parser';

import { ReactComponent as SearchIcon } from '../../images/icons/search.svg';
import { ReactComponent as CameraIcon } from '../../images/icons/camera.svg';
// import { ReactComponent as MicroIcon } from '../../images/icons/microphone.svg';
import { ReactComponent as PuggySearch } from '../../images/icons/puggy_search.svg';

import { getValidResponseBody, searchApi } from '../../api';
import styles from './Searchbar.module.scss';
import constructURL from '../../utils/constructURL';
import { useCategoryLists } from '../../utils/useCategoryLists';
import { getPagePath, isCurrentPath } from '../../utils/appRoutes';
import { useSearchHistory } from '../../utils/useSearchHistory';
import { useSwitchableFeatures } from '../../features/SwitchableFeature/SwitchableFeature';
import { CART, IMAGE_SEARCH_FEATURE } from '../../features/SwitchableFeature/SwitchableFeature.definitions';
import { ARD_CATEGORY_SEARCH, ARD_CHAT, ARD_SEARCH } from '../../utils/appRoutes.definitions';
import { useImageSearch } from '../../utils/useImageSearch';
import getSearchPayload from '../../utils/getSearchPayload';
import ImageUploadMobileWidget from '../ImageUploadMobileWidget/ImageUploadMobileWidget';
import slugify from '../../utils/slugify';

const CategoryPicker = loadable(() => import('../CategoryPicker/CategoryPicker'));
const Suggestions = loadable(() => import('../Suggestions/Suggestions'));
const SearchHistory = loadable(() => import('../SearchHistory/SearchHistory'));
const SearchImageUpload = loadable(() => import('../SearchImageUpload'));

let timeout;
let suggestionsTimeout;

//todo: fix multiple renders after queries
const Searchbar = React.forwardRef(({
  autoFocus = false,
  rootCategorySlug,
  selectedCategory,
  isDesktop,
  searchTerm,
  filters,
  SAYT = false, //search as you type
  suggestionsOnTyping = true,
  className = ''
}, ref) => {
  const intl = useIntl();
  const { categoryTree, loadCategories } = useCategoryLists({ categoryTree: { onCallback: true } });
  const [containmentClassName, setContainmentClassName] = useState('');
  const unsetContainment = () => setContainmentClassName(styles.searchBarUnset);
  const { addHistoryItem } = useSearchHistory();
  const [marketplaceVersion, imageSearchFeature] = useSwitchableFeatures([CART, IMAGE_SEARCH_FEATURE]);

  const rootStyle = classNames(styles.searchBar, 'col-12-xs', 'col-8-xl', 'offset-2-xl', containmentClassName, className);

  const isPlaceholderShown = () => {
    if (searchTerm?.trim()?.length) return false;
    if (autoFocus && !isDesktop) return true;
    if (isDesktop) return true;
    return !searchTerm?.trim()?.length;
  };

  const [showPlaceholder, setShowPlaceholder] = useState(isPlaceholderShown());
  const location = useLocation();
  const { push } = useHistory();
  const searchInput = useRef(null);
  const [suggestions, setSuggestions] = useState([]);
  const [productSugestions, setProductSugestions] = useState([]);
  const [selected, setSelected] = useState(undefined);
  const [isSuggestionsOpened, setIsSuggestionsOpened] = useState(false);
  const [isHistoryOpened, setIsHistoryOpened] = useState(false);
  const [categoryPickerOpen, setCategoryPickerOpen] = useState(false);
  const { onImageSearchFileChange, imageSearch, openUploadImage, handleTriggerUploadImage, handleSetImageUploadUrl } = useImageSearch();
  const lastQueryRef = useRef(undefined);

  const openHistory = () => {
    setIsSuggestionsOpened(false);
    setIsHistoryOpened(true);
    handleTriggerUploadImage(false);
  };

  const openSuggestions = () => {
    setIsSuggestionsOpened(true);
    setIsHistoryOpened(false);
    handleTriggerUploadImage(false);
  };

  const closeSearchQuerySuggestions = () => {
    setIsSuggestionsOpened(false);
    setIsHistoryOpened(false);
  };
  /**
   * Clear containment after hydration so that we can show the dropdown
   */
  useEffect(() => {
    setTimeout(() => {
      unsetContainment();
    }, 750);
  }, []);
  /**
   * Ask for suggestions on user input
   */
  const shouldUpdateSuggestions = (query) => query && query.length >= 3 && query !== lastQueryRef?.current;
  const shouldResetSuggestions = (query) => (!query || query.length < 3) && query !== lastQueryRef?.current
  /**
   * Synchronise state of category picker dropdown
   */
  const categoryPickerCallback = useCallback((isOpen) => {
    setCategoryPickerOpen(isOpen);
  }, []);

  const updateSuggestions = async (query, filters = []) => {
    const options = { method: 'post', body: JSON.stringify({ filters, query }) };
    const searchOptions = {
      //headers: headers,
      method: 'POST',
      body: JSON.stringify(getSearchPayload({ searchTerm: query, context: 'suggestions' })),
    };
    const [suggestionsResp, productsResp] = await Promise.all([searchApi.getSuggestions(options), searchApi.getSearchData(searchOptions)]);
    const products = getValidResponseBody(productsResp);

    if (query?.trim() !== searchInput?.current?.value?.trim()) return;

    const suggestions = getValidResponseBody(suggestionsResp);
    const relatedCategories = products?.top_categories.map((categories) => {
      const categoriesNames = categories.split('>');
      const displayName = categoriesNames.slice(-1).toString();
      const categoriesPath = categoriesNames.map(slugify);
      return {text: displayName, type: 'relatedCategories', catRoute: categoriesPath};
    });
    const extentedSuggestions = [...suggestions, ...relatedCategories];
    setSuggestions(extentedSuggestions);
    setProductSugestions(products?.hits);
  };

  const enqueueSuggestionsRequest = (query, filters = []) => {
    lastQueryRef.current = query;

    if (suggestionsTimeout) {
      clearTimeout(suggestionsTimeout);
    }

    loadCategories();
    suggestionsTimeout = setTimeout(() => {
      updateSuggestions(query, filters);
    }, 600);
  };

  useEffect(() => {
    //clear image search
    if (
      imageSearch &&
      ((location.pathname === getPagePath(ARD_SEARCH) && location.search !== '') ||
        location.pathname !== getPagePath(ARD_SEARCH))
    ) {
      handleSetImageUploadUrl(null);
    }
  }, [location]);

  useEffect(() => {
    //Don't open history/suggestions on mobile
    if (!isDesktop) return;
    //Don't open history/suggestions when searchbar autofocus is disabled
    if (!autoFocus) {
      return;
    }
    // if (typeof searchTerm === 'string' && searchTerm.length <= 1) {
    //   openHistory();
    // }
    //if (searchTerm?.length > 2 && suggestions?.length) openSuggestions();

    if (autoFocus) {
      searchInput.current.focus();
    }

  }, [autoFocus, isDesktop, searchTerm, suggestions?.length]);

  useEffect(() => {
    if (marketplaceVersion) {
      return;
    }
  
    if (suggestions.length) openSuggestions();
    else closeSearchQuerySuggestions();
  }, [marketplaceVersion, suggestions?.length]);

  //createPath is used only to build path from suggestions
  //the only function which invokes createPath is triggerSelection
  //todo: provide paths in suggestions api response
  const createPath = (suggestion, isHistoryItem) => {
    if (isHistoryItem) {
      if (isCurrentPath(ARD_SEARCH, location)) {
        return `?q=${suggestion}`;
      } else {
        return `${getPagePath(ARD_SEARCH)}?q=${suggestion}`;
      }
    }

    if (!suggestion) {
      return undefined;
    }

    const urlSafeText = suggestion.text.replace('&', '%26');
    const srpPath = getPagePath(ARD_SEARCH);

    switch (suggestion.type) {
      case 'category': {
        const rootCategorySlug = Object.keys(categoryTree).find(
          (slug) => categoryTree[slug].key === suggestion.catRoute[0]
        );

        const subCategorySlug =
          suggestion.catRoute.length > 1 &&
          Object.keys(categoryTree[rootCategorySlug].subCategories).find(
            (slug) => categoryTree[rootCategorySlug].subCategories[slug].key === suggestion.catRoute[1]
          );

        const subSubCategorySlug =
          suggestion.catRoute.length > 2 &&
          Object.keys(categoryTree[rootCategorySlug].subCategories[subCategorySlug].subSubCategories).find(
            (slug) =>
              categoryTree[rootCategorySlug].subCategories[subCategorySlug].subSubCategories[slug].key ===
              suggestion.catRoute[2]
          );

        const categories = [rootCategorySlug, subCategorySlug, subSubCategorySlug].filter(Boolean);
        return getPagePath(ARD_CATEGORY_SEARCH, categories);
      }
      case 'relatedCategories': {
        const query = lastQueryRef.current;
        return `${getPagePath(ARD_CATEGORY_SEARCH, suggestion.catRoute)}?q=${query}`;
      }
      case 'brand':
        return `${srpPath}?brand=${urlSafeText}`;
      case 'shop':
        return `${srpPath}?webshop=${urlSafeText}`;
      default:
        return `${srpPath}?q=${urlSafeText}`;
    }
  };

  const triggerSelection = (index) => {
    setSelected(undefined);

    if (!suggestions) {
      return;
    }

    closeSearchQuerySuggestions();
    if (suggestions[index]?.type !== 'relatedCategories') {
      searchInput.current.value = '';
      setShowPlaceholder(true);
    }
    
    if (suggestions.length === 0) {
      return;
    }

    if (suggestions[index]?.type === 'product') {
      setShowPlaceholder(false);
      searchInput.current.value = suggestions[index].text;
    }

    push(
      constructURL(
        createPath(suggestions[index]),
        { ignoreExistingQueryString: true }
      )(location)
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      const searchPhrase = searchInput?.current?.value?.trim() || '';
      if (!SAYT && validateSearchRequest(searchPhrase)) {
        proceedSearch(searchPhrase);
        searchInput.current.blur();
        if (suggestionsOnTyping) closeSearchQuerySuggestions();
      }
      if (!suggestionsOnTyping && shouldUpdateSuggestions(searchPhrase)) {
        enqueueSuggestionsRequest(searchPhrase, filters);
      }
      if (!suggestionsOnTyping && shouldResetSuggestions(searchPhrase)) {
        setSuggestions([]);
        setProductSugestions([]);
      }
      lastQueryRef.current = searchPhrase;
    }
  }

  const clearSearch = () => {
    searchInput.current.value = '';
    setShowPlaceholder(true);
    //proceed empty search
    push(constructURL(`?q=`)(location));
  };

  const proceedSearch = (value) => {
    addHistoryItem(value);
    const replaceValue = value.replace('&', '%26');
    push(constructURL(`${getPagePath(ARD_SEARCH, rootCategorySlug)}?q=${replaceValue}&page=`)(location));
  };

  const enqueueSearchRequest = (value) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      window.scrollTo({
        behavior: 'smooth',
        left: 0,
        top: 0,
      });

      proceedSearch(value);
    }, 450);
  };

  //for mobile when SAYT disabled
  const validateSearchRequest = (value) => value !== searchTerm;
  const initiateSearchHandler = () => {
    closeSearchQuerySuggestions();
    const searchPhrase = searchInput?.current?.value;
    if (validateSearchRequest(searchPhrase)) proceedSearch(searchPhrase);
  };

  const pickHistoryItem = (historyItem) => {
    setShowPlaceholder(false);
    push(constructURL(createPath(historyItem, true), { ignoreExistingQueryString: true })(location));
    searchInput.current.value = historyItem;
    closeSearchQuerySuggestions();
  };

  const handleOpenUploadImage = () => {
    if (openUploadImage) {
      return;
    }
    handleTriggerUploadImage(true);
    setIsHistoryOpened(false);
    setIsSuggestionsOpened(false);
  };

  const handleCloseUploadImage = () => {
    handleTriggerUploadImage(false)
  }

  const handleInput = (e) => {
    const value = e.currentTarget.value?.trim() || '';
    setShowPlaceholder(value.length === 0);
    if (!validateSearchRequest(value)) return;

    //------Search As You Type specific requests------
    if (SAYT && value.length >= 3) enqueueSearchRequest(value);
    if (SAYT && !value.length) clearSearch();
    //-----/Search As You Type specific requests------
    if (suggestionsOnTyping && shouldUpdateSuggestions(value)) {
      enqueueSuggestionsRequest(value, filters);
    }
    if (suggestionsOnTyping && shouldResetSuggestions(value)) {
      setSuggestions([]);
      setProductSugestions([]);
    }
    setCategoryPickerOpen(false);
    lastQueryRef.current = value;
  };

  const clickHandler = (e) => {
    if (e.currentTarget.value.length === 0) {
      if (marketplaceVersion) openSuggestions(); //history is embeded into suggestions here
      if (!marketplaceVersion && isDesktop)  openHistory(); //but not here
      if (!marketplaceVersion && !isDesktop)  openSuggestions();

      //User typed on search bar on mobile
      //todo: change to proceedSearch
      if (!autoFocus && !isDesktop) {
        clearSearch('');
      }
    } else {
      if (!suggestions?.length && shouldUpdateSuggestions(e.currentTarget.value)) enqueueSuggestionsRequest(e.currentTarget.value);
      openSuggestions();
    }
  };

  //onSubmit is blocked to not reload page with form.action location
  const submitHandler = (e) => { e.preventDefault(); };

  const mouseDownHandler = (e) => {
    if (isSuggestionsOpened || isHistoryOpened) {
      e.stopPropagation();
    }
  };

  return (
    <div className={rootStyle} ref={ref}>
      <form
        className={classNames(styles.inputContainer)}
        onSubmit={submitHandler}
      >
        <CategoryPicker
          classList={{ root: classNames(styles.categoryPicker) }}
          selectedCategoryName={selectedCategory?.name}
          isOpen={categoryPickerOpen}
          dropDownCallback={categoryPickerCallback}
        />
        <span className={styles.searchIcon}>
          <SearchIcon />
        </span>
        <div className={classNames(styles.searchPlaceholder, 'col-9-xs', 'col-7-m', 'col-9-l', 'col-7-xl')}>
          <label
            htmlFor="searchBarInput"
            className={classNames({ [styles.searchPlaceholderHidden]: !showPlaceholder })}
          >
            <FormattedMessage
              defaultMessage="Search by product or shop e.g. <b>mobile phone</b>, <b>sneakers</b> or <b>Sun & Sand Sports</b>"
              id="search.placeholder"
            >
              {(text) => (
                <span className={classNames(styles.placeholderText, 'col-9-xs', 'col-7-m', 'col-9-l', 'col-7-xl')}>
                  {typeof text === 'string' && parse(text)}
                </span>
              )}
            </FormattedMessage>
          </label>
          <input
            autoComplete="off"
            className={classNames(styles.searchInput)}
            data-testid="searchbarInput"
            id="searchBarInput"
            defaultValue={searchTerm}
            onMouseDown={mouseDownHandler}
            onClick={clickHandler}
            onInput={handleInput}
            onKeyDown={handleKeyDown}
            ref={searchInput}
          />
        </div>
        <div className={classNames('col-3-xs', 'col-5-m', 'col-3-l')}>
          <div className={styles.searchIcons}>            
            {searchInput.current && searchInput.current.value.length > 0 && (
              <span className={styles.clearIcon} onClick={clearSearch} data-testid="clearInput" title={intl.formatMessage({ id: 'searchbar.clearSearchQuery' })}>
                ×
              </span>
            )}
            <button
                aria-label={intl.formatMessage({ id: 'common.search' })}
                className={classNames(styles.searchButton, {[styles.SearchButtonMP]: marketplaceVersion})}
                onClick={initiateSearchHandler}
                data-testid="SearchbarCTA"
              >
              <SearchIcon fill="#FFF" />
            </button>
            {(marketplaceVersion || imageSearchFeature) && (
              <div className={classNames(styles.searchIconActionsMP, {[styles.searchIconActionsClickout]: !marketplaceVersion})}>
                {/* <MicroIcon /> */}
                {imageSearchFeature && !isDesktop && <ImageUploadMobileWidget onImageSearchFileChange={onImageSearchFileChange} />}
                {imageSearchFeature && isDesktop && <CameraIcon onClick={handleOpenUploadImage} />}
                {marketplaceVersion && <PuggySearch onClick={() => push({ ...location, pathname: getPagePath(ARD_CHAT)})} />}
              </div>
            )}
            
          </div>
        </div>
      </form>
      <Suggestions
          onClose={() => (isSuggestionsOpened ? closeSearchQuerySuggestions() : null)}
          onSelect={(idx) => {
            setSelected(idx);
            triggerSelection(idx);
          }}
          pickHistoryItem={pickHistoryItem}
          query={searchInput?.current?.value}
          selected={selected}
          suggestions={suggestions}
          isOpen={isSuggestionsOpened}
          productSugestions={productSugestions}
          proceedSearch={initiateSearchHandler}
        />
      {!marketplaceVersion && (
        <SearchHistory
          onClose={() => (isHistoryOpened ? closeSearchQuerySuggestions() : null)}
          onSelect={pickHistoryItem}
          isOpen={isHistoryOpened}
        />
      )}
      {imageSearchFeature && openUploadImage && (
        <SearchImageUpload onClose={handleCloseUploadImage} />
      )}
    </div>
  );
});

Searchbar.displayName = 'Searchbar';
Searchbar.propTypes = {
  searchTerm: PropTypes.string,
};

export default Searchbar;
